import { COLORS, JS_FONTS } from "@constants";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Tooltip from "../components/Tooltip";
import { compactStringArray } from "./appUtils";

export const formatDateToEastern = (date: string, fmt: string) => {
  return format(utcToZonedTime(date, "America/New_York"), fmt);
};

export const renderNewlines = (text) =>
  text?.split("\n").map((m, i) => (
    <span key={i}>
      {i ? <br /> : <></>}
      {m}
    </span>
  ));

export const fixMarkdownNewlines = (text) => text?.trim().split("\r\n").join("&nbsp;\n\n");

const matchTooltip = (text) => {
  // searches for tooltips in the form of ????tooltip text ?learnmore=LINK_HERE????
  return text?.match(/\?\?\?\?(.*?)\?\?\?\?/s);
};

export const withoutTooltip = (text) => {
  // remove all tooltip signifiers and contents (????<text>????)
  return text?.replace(/~~\?\?\?\?.*?\?\?\?\?~~/gs, "");
};

const renderTooltip = (text) => {
  if (!matchTooltip(text)) {
    return <span />;
  }

  const toolTipString = matchTooltip(text) && matchTooltip(text)[1];
  const toolTipWithoutLink = toolTipString.replace(/\?learnmore=.*/i, "");

  const linkMatch = toolTipString.match(/\?learnmore=(.*)/i);
  const linkString = linkMatch && linkMatch[1];

  return (
    <span style={{ marginLeft: "3px", position: "relative" }}>
      <Tooltip text={toolTipWithoutLink} linkUrl={linkString} linkText="Learn more" />
    </span>
  );
};

export const renderMarkdownText = (section: string | null, customStyle?) => {
  if (section?.length) {
    // markdown styles
    const styles = {
      heading: {
        marginTop: "1em",
        marginBottom: "1em",
      },
      p: {
        ...JS_FONTS.REGULAR_1,
      },
      li: {
        lineHeight: "30px",
      },
      ul: {
        marginLeft: "18px",
        width: "100%",
        ...JS_FONTS.REGULAR_1,
      },
      hr: {
        marginTop: "45px",
        marginBottom: "45px",
        borderTop: `2px solid ${COLORS.NEUTRAL_200}`,
      },
      bold: {
        fontWeight: 600,
      },
      ...customStyle, // overwrite defaults
    };
    // split for tooltip here
    /* eslint-disable react/no-children-prop */
    return (
      <ReactMarkdown
        remarkPlugins={[gfm]}
        disallowedElements={["code", "html", "pre", "table", "q"]}
        components={{
          // update heading style
          h1: ({ node, ...props }) => {
            return <h3 style={styles.heading} {...props} />;
          },
          h2: ({ node, ...props }) => {
            return <h4 style={styles.heading} {...props} />;
          },
          h3: ({ node, ...props }) => {
            return <h5 style={styles.heading} {...props} />;
          },
          h4: ({ node, ...props }) => {
            return <h6 style={styles.heading} {...props} />;
          },
          h5: ({ node, ...props }) => {
            return <h6 style={styles.heading} {...props} />;
          },
          h6: ({ node, ...props }) => {
            return <h6 style={styles.heading} {...props} />;
          },
          // update p style
          p: ({ node, children, ...props }) => {
            return <p style={styles.p} {...props} children={children} />;
          },
          // Open anchor links in new tab
          a: ({ node, children, ...props }) => {
            return <a target="_blank" rel="noopener noreferrer" {...props} children={children} />;
          },
          strong: ({ node, children, ...props }) => {
            return <strong style={styles.bold} {...props} children={children} />;
          },
          // update horizontal line style
          hr: ({ node, ...props }) => {
            return <hr style={styles.hr} />;
          },
          // Apply special styles to li and ul with correct spacing.
          li: ({ node, ...props }) => (
            // @ts-ignore
            // eslint-disable-next-line react/no-unknown-property
            <li style={styles.li} {...props} ordered={props.ordered.toString()} />
          ),
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          ul: ({ node, ...props }) => <ul style={styles.ul} {...props} ordered="false" />,
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          ol: ({ node, ...props }) => <ol style={styles.ul} {...props} ordered="true" />,
          // custom tooltip in strikethrough block
          del: ({ node, children, ...props }) => {
            if (children.length === 3 && matchTooltip(children.join(""))) {
              const rawText = children[0] + (children[1] as any)?.props.href + children[2];
              return renderTooltip(rawText);
            }
            return renderTooltip(children[0]);
          },
        }}
      >
        {section}
      </ReactMarkdown>
    );
  }
};

export const convertToSnakeCase = (text) => text?.replace(/ /g, "_").toLowerCase();

export const truncate = (str, n) => {
  return str?.substr(0, n - 1) + (str.length > n ? "..." : "");
};

export const bulletTextToStringArray = (bulletText: string | null): string[] => {
  if (!bulletText?.startsWith("* ")) return [];

  return compactStringArray(bulletText.substring(2).split("\n* "));
};

export const stringArrayToBulletText = (list: string[]): string => {
  return `* ${compactStringArray(list).join("\n* ")}`;
};

export const getInitials = (text) => {
  if (!text) return;

  const allNames = text.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    return index === 0 || index === allNames.length - 1
      ? `${acc}${curr.charAt(0).toUpperCase()}`
      : acc;
  }, "");
  return initials;
};

export const isValidURL = (url) =>
  RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/).test(
    url
  );

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatMIMEContentType = (type) => {
  switch (type) {
    case "image/jpeg":
      return "JPG";
    case "image/png":
      return "PNG";
    case "application/pdf":
      return "PDF";
    default:
      return type;
  }
};

export const startsWithVowel = (text: string): boolean => {
  if (typeof text !== "string" || text.length === 0) return false;

  const firstLetterLowercase = text[0].toLowerCase();
  if (["a", "e", "i", "o", "u"].includes(firstLetterLowercase)) return true;
  return false;
};

export const isTrimmedLengthInRange = (
  text: string,
  lowerBound: number,
  upperBound?: number
): boolean => {
  if (typeof text !== "string") return false;

  const trimmedText = text.trim();
  if (upperBound && trimmedText.length >= lowerBound && trimmedText.length <= upperBound) {
    return true;
  }
  if (!upperBound && trimmedText.length >= lowerBound) {
    return true;
  }
  return false;
};

export const listArrayElements = (array: any[]): string => {
  switch (array.length) {
    case 0: {
      return "";
    }
    case 1: {
      return String(array[0]);
    }
    case 2: {
      return `${String(array[0])} and ${String(array[1])}`;
    }
    default: {
      const lastElement = array.slice(-1);
      return `${array.slice(0, array.length - 1).join(", ")} and ${String(lastElement)}`;
    }
  }
};

export const capitalize = (string: string): string => {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export const copyLinkToClipboard = async (currentUserId?: number, link?: string) => {
  const text = constructLinkWithReferrer(currentUserId, link);

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.error("Failed to copy text: ", error);
    throw error;
  }
};

const constructLinkWithReferrer = (currentUserId?: number, link?: string): string => {
  let url = link ?? window.location.href;

  if (currentUserId) {
    const urlObj = new URL(url);
    urlObj.searchParams.set("referrer_id", currentUserId.toString());
    url = urlObj.toString();
  }

  return url;
};
